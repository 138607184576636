import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Locker } from "../../shop-icons";

const ForgotPasswordForm = ({ submitHandler, children }) => {
  const schema = yup.object({
    password: yup
      .string()
      .matches(
        /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{6,30})/,
        `The Password should contain at least 6 chars: 
        one uppercase letter, number and a special character (@#$%!...)`
      )
      .required("Password is Required"),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords don't match")
      .required("Confirm Password is required"),
  });

  return (
    <div className="login100-form max-w-xs">
      <Formik
        validationSchema={schema}
        onSubmit={submitHandler}
        initialValues={{
          passwordConfirm: "",
          password: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <span className="font-display text-blue-900 text-3xl lg:text-4xl text-center block -mt-10 mb-10">
              New password
            </span>
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="password"
                name="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <Locker className="w-5" />
              </span>
            </div>
            {errors.password && touched.password && (
              <p className="ml-5 text-sm font-thin">{errors.password}</p>
            )}
            <div className="w-full relative z-10 mb-1">
              <input
                className="input100"
                type="password"
                name="passwordConfirm"
                placeholder="Confirm your password"
                value={values.passwordConfirm}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <Locker className="w-5" />
              </span>
            </div>
            {errors.passwordConfirm && touched.passwordConfirm && (
              <p className="ml-5 text-sm font-thin">{errors.passwordConfirm}</p>
            )}
            <div className="w-full flex justify-center pt-5">
              <button
                className="login100-form-btn bg-blue-900 font-display tracking-widest font-normal"
                type="submit"
              >
                Submit
              </button>
            </div>
            <>{children}</>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordForm;
