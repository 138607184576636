import React, { useEffect } from "react";
import { navigate } from "gatsby";
import useAuth from "../hooks/useAuth";
import queryString from "query-string";

import SEO from "../components/seo";
import ForgotPasswordForm from "../components/register-form/forgot-password";
import Paws from "../components/paws";
import { GetAuthContextFromLS } from "../utils/secure-local-storage";
import { clientLoginAsync, updateRequestByIdAsync } from "../services/auth";
import Logo from "../../static/assets/img/logo.svg";
import { isBrowser } from "../utils/isBrowser";

const ForgotPassword = (props) => {
  const { state, validateSession } = useAuth();

  const queryParams = props.location.search
    ? queryString.parse(props.location.search)
    : null;

  if (
    state !== undefined &&
    state.isLoggedIn &&
    state.isActive &&
    !state.isExpired
  ) {
    navigate("/platform");
  }

  useEffect(() => {
    let data = GetAuthContextFromLS();

    validateSession(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButton = () => {
    navigate("/");
  };

  const handleSubmitForm = async (data) => {
    let token = await clientLoginAsync();
    await updateRequestByIdAsync(queryParams.request, data.password, token);
  };

  if (isBrowser && (!queryParams || queryParams.request === undefined))
    navigate("/");

  return (
    <div className="w-full wrapper">
      <SEO title="Meia Pata B2B Platform" />
      <div className="login-container-b">
        <div className="absolute top-0 bottom-0 right-0 left-0 overflow-hidden z-0">
          <Paws
            className="fill-current text-yellow-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "19deg",
              right: "10%",
              top: 10,
            }}
          />
          <Paws
            className="fill-current text-pink-500 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "10deg",
              right: "40%",
              top: 115,
            }}
          />
          <Paws
            className="fill-current text-blue-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-40deg",
              left: "1%",
              top: 200,
            }}
          />
          <Paws
            className="fill-current text-blue-100 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-40deg",
              left: "44%",
              top: 700,
            }}
          />
          <Paws
            className="fill-current text-pink-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "27deg",
              right: "1%",
              top: 800,
            }}
          />
          <Paws
            className="fill-current text-yellow-500 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "27deg",
              left: "30%",
              top: 1350,
            }}
          />
        </div>
        <div
          className={`container py-20 md:py-36 md:max-w-screen-lg bg-white flex justify-around rounded shadow-4xl z-10`}
        >
          <div className="hidden md:block ml-10 text-center">
            <img src={Logo} alt="Meia Pata Logo" />
            <p className="hidden md:block text-base font-display mt-5 text-gray-500 -ml-3.5">
              Try login again!{" "}
              <button
                className="text-blue-700 cursor-pointer underline"
                onClick={handleButton}
              >
                Click here
              </button>
            </p>
          </div>
          <ForgotPasswordForm submitHandler={handleSubmitForm}>
            <p className="md:hidden text-base font-display mt-5 text-gray-500 text-center">
              Try login again?{" "}
              <button
                className="text-blue-700 cursor-pointer underline"
                onClick={handleButton}
              >
                Click here
              </button>
            </p>
          </ForgotPasswordForm>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
